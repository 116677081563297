import React from "react";
import PropTypes from "prop-types";
import "twin.macro";

import Layout from "../components/layout";

export default function mdxTemplate({ children }) {
  return (
    <Layout>
      <article className="prose" tw="prose font-sans my-16 mx-auto">
        {children}
      </article>
    </Layout>
  );
}

mdxTemplate.propTypes = {
  children: PropTypes.any,
};
